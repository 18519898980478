'use strict';

const wishlistModule = require('base_wishlist_alimerka/wishlist/wishlist');

function beforeCreateWishlistEvent(pid) {
    $('body').on('before:createWishlist', () => {
        const url = $('.new-wishlist').data('url');
        const listName = $('.popup__textInput').val();
        if (listName !== '' && pid) {
            $('.new-wishlist').data('url', url + '?listName=' + listName + '&listProduct=' + pid);
        } else if (listName) {
            $('.new-wishlist').data('url', url + '?listName=' + listName);
        } else if (pid) {
            $('.new-wishlist').data('url', url + '?listProduct=' + pid);
        }
    });
}

function afterCreateWishlistEvent() {
    $('body').on('after:createWishlist', (event, createListData) => {
        $('.js-wishlist-list-items').append(createListData.renderedListWithProduct);
        $('.new-wishlist').data('url', createListData.createListUrl);
    });
}

function openModal(data, pid) {
    $('#wishListModalResponse').html('');

    // Muestra los botones en el modal
    $('#wishListModalResponse').html($(data.renderedTemplate).find('#wishListModalResponse').html());
    $('body').off('click', '.new-wishlist');
    $('body').off('before:createWishlist');
    $('body').off('after:createWishlist');
    wishlistModule.addNewWishlist();

    beforeCreateWishlistEvent(pid);

    afterCreateWishlistEvent();

    // Abre el modal
    $('#wishListModal').modal('show');
    $('.modal-backdrop').css({
        'background-color': 'rgba(0, 0, 0, 0.2)'
    });
}

/**
 * Calculates the formatted quantity of a product based on its conversion factor.
 *
 * @param {jQuery} element - A jQuery object representing the HTML element containing the `productconversion` data attribute.
 * @param {string} pid - The product ID used to identify the quantity input element in the DOM.
 * @returns {number} - The formatted quantity, obtained by dividing the selected quantity by the product conversion factor.
 */
function getFormattedQuantity(element, pid) {
    const quantityToAdd = $('#itemquantity-' + pid);
    const productConversion = element.data('productconversion');
    const selectedQuantity = parseFloat(quantityToAdd.val(), 10);
    return selectedQuantity / productConversion;
}

module.exports = {
    openModal: openModal,
    getFormattedQuantity
};
